import { defineComponent, reactive } from "@vue/runtime-core";

import style from './index.module.less'

export default defineComponent({
  setup() {

    const state = reactive({
      username: '',
      radio: 1,
      input: true,
      switchChecked: false,
      birthday: '',
      showCalendar: false,
    })

    const onConfirm = (date: Date) => {
      console.log(1256)
      state.birthday = `${date.getMonth() + 1}/${date.getDate()}`;
      state.showCalendar = false;
    }

    const close = () => {
      console.log(122121)
    }

    return () => {
      return (
        <div class={style.container}>
          <van-form onSubmit="onSubmit">
            <van-field
              v-model={state.username}
              name="用户名"
              label="用户名"
              placeholder="用户名"
              rules={[{ required: true, message: '请填写用户名' }]}
            />
            <van-field name="switch" label="性别" >
              {{
                input: () => <van-radio-group v-model={state.radio} direction="horizontal">
                                <van-radio name="1">男</van-radio>
                                <van-radio name="2">女</van-radio>
                              </van-radio-group>
              }}
            </van-field>
            <van-field
              v-model={state.birthday}
              readonly
              clickable
              name="calendar"
              label="日历"
              placeholder="点击选择日期"
              onClick={() => state.showCalendar = true }
            />
            <van-calendar
              v-model={[state.showCalendar, "show"]}
              onConfirm={onConfirm}
              onclose={() => state.showCalendar = false }
              /* onClose={close} */
            />
            <div style="margin: 16px;">
              <van-button round block type="info" native-type="submit">提交</van-button>
            </div>
          </van-form>
        </div>
      )
    }
  }
})